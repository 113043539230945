import React from "react"
import "./postThumbnail.css"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import { isMobile } from "../utils/helpers"
import ReactMarkdown from "react-markdown"

const PostThumbnail = ({ title, description, image, id, date, slug }) => {
  return (
    <div key={id} className="PostThumbnail">
      {image && (
        <LazyLoad width="200px" height="150px">
          <img src={image} alt={title} className="PostThumbnail__image " />
        </LazyLoad>
      )}

      <div>
        <header className="PostThumbnail__header">
          <Link to={`/blog/${slug}`} className="PostThumbnail__link">
            <h3>{title}</h3>
          </Link>
          <small className="PostThumbnail__header__date">
            {new Date(date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </small>
        </header>
        <main>
          <ReactMarkdown source={description} />
        </main>
        <Link to={`/blog/${slug}`} className="PostThumbnail__link">
          Read More →
        </Link>
      </div>
    </div>
  )
}

export default PostThumbnail
