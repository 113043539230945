import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import PostThumbnail from "../components/postThumbnail"
import "./index.css"

const IndexPage = ({ data }) => {
  const {
    allContentfulBlogPost: { nodes },
  } = data
  return (
    <React.Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <title>Mostafa Darehzereshki</title>
      </Helmet>
      <Layout>
        <section>
          <h2>My Recent Posts</h2>
          {nodes.map(
            ({
              description: { description },
              id,
              title,
              heroImage,
              publishDate,
              slug,
            }) => (
              <PostThumbnail
                key={id}
                description={description}
                title={title}
                image={heroImage ? heroImage.file.url : null}
                date={publishDate}
                slug={slug}
              />
            )
          )}
        </section>
        <div className="ViewAll__link__container">
          <Link to="/blog" className="ViewAll__link">
            View All Posts
          </Link>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulBlogPost(limit: 3) {
      nodes {
        id
        description {
          description
        }
        slug
        title
        publishDate
        heroImage {
          file {
            url
          }
        }
      }
    }
  }
`

export default IndexPage
